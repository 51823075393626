<template>
    <component class="content-card-heading" :is="headingComponent" :class="headingClasses">
        {{ heading }}
    </component>
</template>

<script>
export default {
    name: 'ContentCardHeading',
    props: {
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        marginBottom: {
            required: false,
            type: String,
            default: 'mb-0',
        },
        truncated: {
            required: false,
            type: [Boolean, Number],
            default: true,
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        dark: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        route: {
            required: false,
            type: [String, Object],
            default: null,
        },
        href: {
            required: false,
            type: String,
            default: null,
        },
    },
    computed: {
        headingClasses () {
            const cls = {};
            cls['text-lg font-medium tracking-tight sm:text-lg'] = true;
            cls[this.headingColor] = !!this.headingColor;
            cls[this.marginBottom] = true;
            cls['truncate'] = !!this.truncated;
            cls['text-center'] = !!this.textCentered;
            cls['text-zinc-700 hover:text-zinc-950'] = !this.headingColor && !this.dark;
            cls['text-zinc-200 hover:text-white'] = !this.headingColor && !!this.dark;
            cls['text-white bg-transparent'] = !this.headingColor && !!this.dark;
            return cls;
        },
    },
};
</script>
