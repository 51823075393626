<template>
    <div class="content-card-text" :class="contentClasses">
        <ContentCompiled :html="html"/>
    </div>
</template>

<script>
export default {
    name: 'ContentCardText',
    props: {
        html: {
            required: false,
            type: String,
            default: null,
        },
        contentColor: {
            required: false,
            type: String,
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        dark: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
    },
    computed: {
        contentClasses () {
            const cls = {};
            cls['max-w-2xl text-sm'] = true;
            cls[this.contentColor] = !!this.contentColor;
            cls['text-center mx-auto'] = !!this.textCentered;
            cls['text-zinc-500'] = !this.contentColor && !this.dark;
            cls['text-zinc-300'] = !this.contentColor && !!this.dark;
            cls['text-white'] = !this.contentColor && !!this.dark;
            return cls;
        },
    },
};
</script>
