<template>
    <component :is="component" v-bind="bindings" class="relative">
        <ImageOptimized
            class="h-auto w-full"
            :class="imageClasses"
            :src="image"
            :alt="imageAlt"
            :loading="imageLoading"
            :fetchpriority="fetchPriority"
            sizes="284px"
        />
        <div v-if="imageOverlayLocked" class="absolute top-2 right-2 p-1 text-white bg-black bg-opacity-50 rounded-full">
            <IconLocked :size="4" class="text-white"></IconLocked>
        </div>
    </component>
</template>

<script>

export default {
    name: 'ImageCard',
    props: {
        image: {
            required: false,
            type: String,
            default: null,
        },
        imageAlt: {
            required: false,
            type: String,
            default: '',
        },
        imageAspectRatio: {
            required: false,
            type: String,
            default: null,
        },
        imageObjectFit: {
            required: false,
            type: String,
            default: 'object-cover',
        },
        imageObjectPosition: {
            required: false,
            type: String,
            default: 'object-top',
        },
        imageOverlayLocked: {
            required: false,
            type: [Boolean, Number],
            default: null,
        },
        imageLoading: {
            required: false,
            type: String,
            default: 'lazy', // eager, lazy
        },
        fetchPriority: {
            required: false,
            type: String,
            default: 'low', // high, low, auto
        },
        route: {
            required: false,
            type: [String, Object],
            default: null,
        },
        href: {
            required: false,
            type: String,
        },
        roundedTop: {
            required: false,
            type: [Boolean, Number],
            default: null,
        },
    },
    computed: {
        imageClasses () {
            const cls = {};
            cls[this.imageAspectRatio] = true;
            cls[this.imageObjectFit] = true;
            cls[this.imageObjectPosition] = true;
            cls['rounded-lg'] = !this.roundedTop;
            cls['rounded-t-lg'] = !!this.roundedTop;
            cls['cursor-pointer hover:shadow-lg'] = !!this.route || !!this.href;
            return cls;
        },
        component () {
            if(this.route) {
                return 'RouterLink';
            }
            if(this.href) {
                return 'a';
            }
            return 'div';
        },
        bindings () {
            if(this.route) {
                return {
                    to: this.route,
                };
            }
            if(this.href) {
                return {
                    href: this.href,
                };
            }
        }
    },
};
</script>
