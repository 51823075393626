<template>
    <component :is="cardComponent" class="layout-card" :class="cardClasses" v-bind="bindings">
        <div class="flex flex-1 flex-col space-y-3">
            <ImageCard
                v-if="image"
                :image="image"
                :imageAlt="imageAlt"
                :imageAspectRatio="imageAspectRatio"
                :imageObjectFit="imageObjectFit"
                :imageObjectPosition="imageObjectPosition"
                :imageOverlayLocked="imageOverlayLocked"
                :imageLoading="imageLoading"
                :fetchPriority="fetchPriority"
            ></ImageCard>
            <div
                v-if="heading || excerpt"
                :class="cardTextAreaClasses"
            >
                <ContentCardHeading
                    v-if="heading"
                    :heading="heading"
                    :truncated="truncated"
                    :headingComponent="headingComponent"
                    :dark="dark"
                    :route="route"
                    :href="href"
                ></ContentCardHeading>
                <ContentCardText
                    v-if="excerpt"
                    :html="excerpt"
                    :dark="dark"
                ></ContentCardText>
            </div>
        </div>
    </component>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    route: {
        required: false,
        type: [String, Object],
        default: null,
    },
    href: {
        required: false,
        type: String,
    },
    target: {
        required: false,
        type: String,
        default: null,
    },
    heading: {
        required: false,
        type: String,
        default: null,
    },
    truncated: {
        required: false,
        type: [Boolean, Number],
        default: true,
    },
    headingComponent: {
        required: false,
        type: String,
        default: 'p',
    },
    excerpt: {
        required: false,
        type: String,
        default: null,
    },
    textCentered: {
        required: false,
        type: [Boolean, Number],
        default: false,
    },
    image: {
        required: false,
        type: String,
        default: null,
    },
    imageAlt: {
        required: false,
        type: String,
        default: '',
    },
    imageAspectRatio: {
        required: false,
        type: String,
        default: null,
    },
    imageObjectFit: {
        required: false,
        type: String,
        default: 'object-cover',
    },
    imageObjectPosition: {
        required: false,
        type: String,
        default: 'center',
    },
    imageOverlayLocked: {
        required: false,
        type: [Boolean, Number],
        default: null,
    },
    imageLoading: {
        required: false,
        type: String,
        default: undefined,
    },
    fetchPriority: {
        required: false,
        type: [String, undefined],
        default: undefined,
    },
    dark: {
        required: false,
        type: [Boolean, Number],
        default: false,
    },
});

const cardComponent = computed(() => {
    if (props.route) return 'router-link';
    if (props.href) return 'a';
    return 'div';
});

const bindings = computed(() => {
    const bindingsObj = {};
    if (props.route) bindingsObj.to = props.route;
    if (props.href) bindingsObj.href = props.href;
    return bindingsObj;
});

const cardClasses = computed(() => {
    const cls = {};
    cls['col-span-1 flex flex-col divide-y bg-inherit'] = true;
    cls['text-center'] = !!props.textCentered;
    return cls;
});

const cardTextAreaClasses = computed(() => {
    const cls = {};
    cls['mt-3'] = !props.image;
    cls['flex flex-1 flex-col space-y-0  bg-inherit px-2 pb-4'] = true;
    cls['cursor-pointer'] = !!props.route || !!props.href;
    return cls;
});
</script>
